import { createRouter, createWebHistory } from "vue-router";
function loadView(view) {
    return () => import(`@/pages/${view}.vue`)
}

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'active',
    routes: [
        { path: '/:notFound(.*)', redirect: '/' },
        {
            path: '/',
            component: loadView('About')
        },
        {
            path: '/contact-me',
            component: loadView('Contact')
        },
        {
            path: '/my-cv',
            component: loadView('MyCV')
        },
        {
            path: '/gallery',
            component: loadView('Gallery')
        },
        {
            path: '/research-project',
            component: loadView('ResearchProject')
        },
        {
            path: '/research-statement',
            component: loadView('ResearchStatement')
        },
        {
            path: '/teaching-statement',
            component: loadView('TeachingStatement')
        },
        {
            path: '/math-stat',
            component: loadView('MathandStat')
        },
        {
            path: '/gsu',
            component: loadView('GSU')
        },
        {
            path: '/mathema',
            component: loadView('Mathema')
        }
    ]
})

export default router