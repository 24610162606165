<template>
<router-view />
<!-- <small-size /> -->
</template>

<script>
// import SmallSize from '@/components/SmallSize.vue'
export default {
  name: 'App',
  data() {
    return {
    }
  },
  // components: { SmallSize }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, Rubik, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
